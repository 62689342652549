import { email } from "./emailTemplate";
import { numberRounder, findOverallLevelRanking } from "./utils";
import { AWSconfig } from "./config";

export const sendEmail = (assessmentID, personalDetails, totalScore) => {
  // Load the AWS SDK for Node.js
  var AWS = require("aws-sdk");

  const { Email, FirstName, LastName } = personalDetails;

  let level = findOverallLevelRanking(totalScore);

  const score = numberRounder(totalScore, 0);

  const adobeEmail = "adobe@station10.co.uk";

  var creds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSconfig.identityPoolId,
  });
  AWS.config.credentials = creds;
  // Set the region

  AWS.config.update({ region: "eu-west-1" });

  // Create sendEmail params
  var params = {
    Destination: {
      /* required */
      CcAddresses: [
        //   'EMAIL_ADDRESS',
        /* more items */
      ],
      BccAddresses: [adobeEmail],
      ToAddresses: [
        Email,
        /* more items */
      ],
    },
    Message: {
      /* required */
      Body: {
        /* required */
        Html: {
          Charset: "UTF-8",
          Data: email(FirstName, LastName, score, assessmentID, level),
        },
        Text: {
          Charset: "UTF-8",
          Data: `<div>
          This is a placeholder for the div in the text field, we are not sure where this is appearing
          </div>
          `,
        },
      },
      Subject: {
        Charset: "UTF-8",
        Data: `Your Adobe Assessment Results by Station10`,
      },
    },
    Source: "Station10 <adobe@station10.co.uk>" /* required */,
    ReplyToAddresses: [
      "adobe@station10.co.uk",
      /* more items */
    ],
  };

  // //Create the promise and SES service object

  var sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(params)
    .promise();

  // Handle promise's fulfilled/rejected states
  sendPromise
    .then(function (data) {
      // console.log(data.MessageId);
    })
    .catch(function (err) {
      // console.error(err, err.stack);
    });
};
