import RiseLoader from "react-spinners/RiseLoader";

const Loader = props => {
  return (
    <>
      <div className="loader">
        <RiseLoader color="#ff5c00" />
        <p>{props.text}</p>
      </div>
    </>
  );
};

export default Loader;
