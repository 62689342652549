import * as React from "react";
import { useParams } from "react-router-dom";
import { dynamoDBGetItem } from "./dynamoDB_GetItem";
import ResultComponent from "./ResultComponent";
import { useState, useEffect } from "react";
import validator from "validator";
import ErrorPage from "./ErrorPage";
import { throttle } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

const LandingPage = () => {
  const [showError, setShowError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [results, setResults] = useState();
  const THROTTLE_INTERVAL = 10000; // Throttle interval in milliseconds
  let data;
  const { assessmentID } = useParams();
  ///check if assessmentId matches the pattern otherwise don't bother to check database

  const getItem = async () => {
    data = await dynamoDBGetItem(assessmentID);

    // // TEST DATA BELOW - DO NOT REMOVE
    // data = {
    //   Item: {
    //     overallScore: {
    //       N: "2.8",
    //     },
    //     dataCollectionAndIntegrationQ5: {
    //       S: "Advanced Data Layer capturing a wide range of data points and structured hierarchy",
    //     },
    //     dataCollectionAndIntegrationQ4: {
    //       S: "Advanced mobile app tracking with comprehensive data points and integration",
    //     },
    //     dataCollectionAndIntegrationQ3: {
    //       S: "Integration with multiple non-Adobe tools, bi-directional data flow",
    //     },
    //     dataCollectionAndIntegrationQ2: {
    //       S: "Integration with multiple Adobe tools, leveraging synergies and functionalities",
    //     },
    //     dataCollectionAndIntegrationQ1: {
    //       S: "Tracking on most pages with a good range of data points",
    //     },
    //     categoryObject: {
    //       S: '{"category1":[0.5,0.75,0.6000000000000001,0.44999999999999996,0.44999999999999996]}',
    //     },
    //     category1: {
    //       N: "2.75",
    //     },
    //     email: {
    //       S: "testApp@test.com",
    //     },
    //     firstName: {
    //       S: "testApp",
    //     },
    //     submissionDate: {
    //       S: "2023-05-09T14:52:05.573Z",
    //     },
    //     lastName: {
    //       S: "testApp",
    //     },
    //     assessmentID: {
    //       S: "ec6d8c14-7b5c-431e-9a96-fc1625a1a645",
    //     },
    //     categoryObjectUnWeighted: {
    //       S: '{"category1":[2,3,3,3,3]}',
    //     },
    //   },
    // };
    if (data === false) {
      //set state to error,
      setShowError(true);
    } else {
      //function to create schema and change state
      setResults({
        totalScore: data.Item.overallScore.N,
        responses: {
          dataCollectionAndIntegrationQ1:
            data.Item.dataCollectionAndIntegrationQ1.S,
          dataCollectionAndIntegrationQ2:
            data.Item.dataCollectionAndIntegrationQ2.S,
          dataCollectionAndIntegrationQ3:
            data.Item.dataCollectionAndIntegrationQ3.S,
          dataCollectionAndIntegrationQ4:
            data.Item.dataCollectionAndIntegrationQ4.S,
          dataCollectionAndIntegrationQ5:
            data.Item.dataCollectionAndIntegrationQ5.S,
        },
        personalDetails: {
          LastName: data.Item.lastName,
          Other: data.Item.email,
          Email: data.Item.email,
          FirstName: data.Item.firstName,
        },
        categoryArray: [],
        categoryObject: JSON.parse(data.Item.categoryObject.S),
        categoryObjectUnWeighted: JSON.parse(
          data.Item.categoryObjectUnWeighted.S
        ),
      });
      setIsReady(true);
    }
  };

  const throttledGetItem = throttle(async () => {
    setIsReady("loading");
    await getItem();
  }, THROTTLE_INTERVAL);

  const assessmentChecker = async () => {
    if (validator.isUUID(assessmentID)) {
      // Check if the getItem call was made within the last throttle interval
      const lastGetItemTime = window.localStorage.getItem("lastGetItemTime");
      if (
        !lastGetItemTime ||
        Date.now() - lastGetItemTime > THROTTLE_INTERVAL
      ) {
        await throttledGetItem();
        window.localStorage.setItem("lastGetItemTime", Date.now().toString());
        // Function hasnt run - Log it has run now;
      } else if (
        !lastGetItemTime ||
        Date.now() - lastGetItemTime < THROTTLE_INTERVAL
      ) {
        //failed against throttle, attempted too many times recently
        toast.warn("Results requested too many times - Please wait 5 seconds");
      }
    } else {
      //Assessment ID is not in correct format of uuid14
      setShowError(true);
    }
  };

  useEffect(() => {
    assessmentChecker();
  }, [assessmentID]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {showError === true && <ErrorPage />}
      {isReady === "loading" && <Loader text={"Retrieving your results"} />}
      {showError === false && isReady === true && (
        <ResultComponent section={"category1"} responses={results} />
      )}
    </>
  );
};

export default LandingPage;
