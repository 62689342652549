import "./survey.scss";

const Header = () => {
  const s10Logo =
    "https://station10.co.uk/wp-content/uploads/2021/02/station10-logo-white.svg";
  const adobeLogo =
    "https://www.glasspaper.no/globalassets/leverandorlogoer/adobe-logo.png?width=1600&quality=60";
  return (
    <>
      <div className="surveyContainer">
        <h1>Data Capability Assessment</h1>
        <div className="logoContainer">
          <p>by</p>
          <img className="s10logo" src={s10Logo} alt="station10 logo"></img>
          <p> in partnership with</p>
          <img className="adobeLogo" src={adobeLogo} alt="adobe logo"></img>
        </div>
      </div>
    </>
  );
};

export default Header;
