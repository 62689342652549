export const categoryWeights = {
  category1: 0.25,
  category2: 0.225,
  category3: 0.175,
  category4: 0.2,
  category5: 0.15,
};

export const valueOfImprovement = {
  category1: {
    level1: `<div>
                <p>
                1. Improved Data Accuracy and Quality: Improvement helps businesses establish a foundation for accurate and high-quality data collection. This lays the groundwork for better decision-making and performance measurement.
                </p>
                <p>
                2. Enhanced Customer Experience: Implementing basic Adobe Analytics tracking and integration allows businesses to gain insights into customer behaviour and preferences, ultimately leading to improved customer experiences.
                </p>
                <p>
                3. Streamlined Processes: Integrating Adobe Analytics with other Adobe and non-Adobe tools helps streamline processes, making it easier for teams to access and analyse data from multiple sources.
                </p>
            </div>`,
    level2: `<div>
                <p>
                1. Deeper Insights and Analysis: Improvement enables businesses to collect more detailed data, providing deeper insights into customer behaviour, preferences, and trends. This facilitates better-informed decision-making and strategy development.
                </p>
                <p>
                2. Increased Efficiency: Enhanced integration between Adobe Analytics and other tools helps streamline data analysis and reporting processes, improving efficiency and collaboration across teams.
                </p>
                <p>
                3. More Effective Marketing and Personalisation: By leveraging more comprehensive data, businesses can optimise marketing efforts and personalise experiences, resulting in better customer engagement and higher conversion rates.
                </p>
            </div>`,
    level3: `<div>
                <p> 1. Enhanced Data-Driven Decision Making: Improvement allows businesses to leverage more sophisticated data analysis capabilities, leading to better-informed decisions and more effective strategies. </p>
                <p> 2. Holistic Customer Understanding: Advanced data collection and integration enable businesses to develop a more comprehensive understanding of their customers, including cross-platform behaviour and preferences, which facilitates more targeted and personalised experiences.</p>
                <p> 3. Streamlined Data Management: By optimising data collection and integration processes, businesses can improve data management efficiency and reduce the time spent on manual data manipulation and aggregation. </p>
            </div>`,
    level4: `<div>
                <p> 1. Expert-Level Data-Driven Decision Making: Improvement enables businesses to leverage the full potential of Adobe Analytics, maximising the value of their data and empowering them to make more precise and informed decisions. </p>
                <p> 2. Highly Personalised Customer Experiences: Expert-level data collection and integration allow businesses to deliver highly personalised and relevant customer experiences across all touchpoints, leading to increased customer satisfaction and loyalty.</p>
                <p> 3. Competitive Advantage: By fully optimising Adobe Analytics capabilities, businesses can gain a significant competitive advantage, driving innovation and outperforming competitors in their respective markets.</p>
            </div>`,
    level5: `<div>
            <p>
            1. Market-Leading Data-Driven Decision Making: Improvement enables businesses to harness the full power of Adobe Analytics, maximising the value of their data and empowering them to make strategic and informed decisions that drive success. 
            </p>
            <p>
            2. Unparalleled Personalised Customer Experiences: Expert-level data collection and integration allow businesses to deliver highly personalised and relevant customer experiences across all channels, resulting in increased customer satisfaction and loyalty.
            </p>
            <p>
            3. Industry-Leading Competitive Advantage: By fully optimising Adobe Analytics capabilities, businesses can achieve an industry-leading competitive advantage, driving innovation and outpacing competitors in their respective markets. 
            </p>
        </div>`,
  },
};

export const exampleKPIsToFocus = {
  category1: {
    level1: `<div>
    <p>
    1. Bounce Rate: With basic tracking in place, businesses can analyse bounce rates and identify areas where improvements can be made to retain visitors and encourage further engagement
    </p>
    <p>
    2. Page Views and Time on Site: Basic tracking allows businesses to monitor page views and time spent on the site, providing insights into which pages are most engaging and where improvements can be made.
    </p>
    <p>
    3. Conversion Rates: Implementing basic tracking helps businesses track conversion rates for specific goals, such as newsletter sign-ups, purchases, or form submissions.
    </p>
    <p>
    4. Cross-Platform Data Consistency: Basic integration with other Adobe and non-Adobe tools helps ensure consistent data collection and reporting across platforms, leading to more accurate performance measurement and analysis.
    </p>
              </div>`,
    level2: `<div>
                <p>
                1. User Engagement Metrics: Comprehensive tracking enables businesses to monitor a wider range of user engagement metrics, such as clicks, scrolls, and video plays, helping to identify opportunities for improvement and optimisation.
                </p>
                <p>
                2. E-Commerce Metrics: Collecting detailed e-commerce data, such as product views, add-to-cart actions, and revenue, helps businesses better understand customer purchase behaviour and optimise their online store.
                </p>
                <p>
                3. Cross-Channel Performance: Advanced integration with other Adobe and non-Adobe tools allows businesses to more effectively measure and optimise cross-channel performance, ensuring a consistent and cohesive customer experience.
                </p>
                <p>
                4. Return on Marketing Investment (ROMI): With a deeper understanding of customer behaviour and more effective marketing efforts, businesses can achieve a higher return on marketing investment, driving growth and profitability.
                </p>
              </div>`,
    level3: `<div> 
   <p> 1. Customer Lifetime Value (CLV): With a more comprehensive understanding of customer behaviour and preferences, businesses can optimise their marketing efforts and improve customer retention, increasing overall customer lifetime value. </p>
   <p> 2. Customer Acquisition Cost (CAC): Leveraging advanced data analysis capabilities and a fully integrated data ecosystem, businesses can optimise their marketing spend and reduce customer acquisition costs.</p>
   <p> 3. Customer Satisfaction and Net Promoter Score (NPS): By delivering more targeted and personalised experiences, businesses can improve customer satisfaction and loyalty, resulting in higher Net Promoter Scores.</p>
   <p> 4. Multi-Channel Attribution: With a fully integrated data ecosystem, businesses can more accurately measure and attribute performance across multiple channels and touchpoints, leading to better-informed marketing decisions and resource allocation.</p>
    </div>`,
    level4: `<div>
                  <p> 1. Return on Investment (ROI): Expert-level tracking and integration enable businesses to optimise their marketing spend and efforts, resulting in a higher return on investment across all channels. </p>
                  <p> 2. Conversion Rate Optimisation: With advanced data collection and analysis capabilities, businesses can identify areas for improvement in the conversion funnel, leading to higher conversion rates and increased revenue. </p>
                  <p> 3. Customer Retention and Churn Rate: Highly personalised customer experiences contribute to improved customer retention and reduced churn rates, directly impacting the bottom line. </p>
                  <p> 4. Market Share and Growth: Leveraging expert-level Adobe Analytics capabilities enables businesses to gain a competitive edge, driving market share growth and overall business success. </p>
              </div>`,
    level5: `<div>
              <p>
              1. Expert-level tracking and integration enable businesses to continuously improve their marketing efforts, resulting in optimised performance and higher returns on investment across all channels
              </p>
              <p>
              2. Advanced Conversion Funnel Optimisation: With granular data collection and analysis capabilities, businesses can identify opportunities for improvement throughout the conversion funnel, leading to higher conversion rates and increased revenue. 
              </p>
              <p>
              3. Enhanced Customer Retention and Reduced Churn: Highly personalised customer experiences contribute to improved customer retention and reduced churn rates, directly impacting the bottom line. 
              </p>
              <p>
              4. Dominant Market Share and Sustained Growth: Leveraging expert-level Adobe Analytics capabilities enables businesses to achieve a dominant market share and drive sustainable growth, ensuring long-term success.
              </p>
          </div>`,
  },
};

export const exampleUseCases = {
  category1: {
    level1: `<div>
                  <p>
                  N/A (No tracking)
                  </p>
                  <p>
                  N/A (No integration)
                  </p>
                  <p>
                  N/A (No integration with non-Adobe tools)
                  </p>
              </div>`,
    level2: `<div>
                  <p>
                  A small business owner tracks basic website traffic metrics to identify popular pages and adjust content accordingly.
                  </p>
                  <p>
                  A content manager uses Adobe Analytics data to identify the best-performing articles and optimise the content creation process.
                  </p>
                  <p>
                  A sales manager uses CRM integration to understand the correlation between customer behaviour on the website and sales performance.
                  </p>
              </div>`,
    level3: `<div> 
                  <p> A marketing manager tracks user behaviour across multiple channels (e.g., web, mobile, social) to optimise campaigns. </p>
                  <p>A digital strategist integrates Adobe Analytics with other Adobe tools to gain a comprehensive view of user behaviour and optimise user journeys.</p>
                  <p>An operations manager integrates ERP and CRM systems with Adobe Analytics for a comprehensive view of sales and inventory data. </p>
            </div>`,
    level4: `<div>
                  <p> An e-commerce company tracks user behaviour across their website and customizes product recommendations based on the data. </p>
                  <p> A large organization optimises the integration of multiple Adobe tools to improve data flow and user experience. </p>
                  <p> A data-driven company integrates Adobe Analytics with various third-party tools to gain a comprehensive view of their users and operations. </p>
              </div>`,
    level5: `<div>
                  <p>
                  An online retailer uses Adobe Analytics to create personalised experiences for visitors based on their browsing history and preferences.
                  </p>
                  <p>
                  A large enterprise fully integrates the Adobe ecosystem to maximise data utilisation and create seamless user experiences.
                  </p>
                  <p>
                  A multinational corporation integrates Adobe Analytics with non-Adobe tools to create a holistic data analysis ecosystem.
                  </p>
            </div>`,
  },
};

export const subCategories = {
  category1: {
    section1: {
      title: "Adobe Analytics Implementation",
      level1: {
        subtitle: "Essential Tracking Foundations",
        text: "Implement basic tracking on critical pages, capturing limited data points and custom events, and establish consistent naming conventions and basic reporting capabilities.",
      },
      level2: {
        subtitle: "Expanded Tracking & Reporting",
        text: "Extend tracking to most pages, capture broader data points, improve custom events tracking, enhance reporting capabilities, and implement continuous improvement processes.",
      },
      level3: {
        subtitle: "Advanced Analytics & Insights",
        text: "Implement comprehensive tracking across all pages, capture detailed data points, track advanced custom events, and utilise advanced segmentation and calculated metrics for in-depth analysis.",
      },
      level4: {
        subtitle: "Optimised Tracking & Performance",
        text: "Develop an optimised tracking strategy focused on KPIs and critical user flows, ensure granular data capture, automate data validation, and provide expert training and documentation for continuous improvement.",
      },
      level5: {
        subtitle: "Pioneer in-depth and predictive analytics",
        text: "Leverage data for predictive modeling and forecasting. Establish industry-leading benchmarks.",
      },
    },
    section2: {
      title: "Adobe tools integration",
      level1: {
        subtitle: "Adobe Tool Integration Foundations",
        text: "Establish basic integration with a single Adobe tool, identify simple use cases, and provide internal training on the integration process for data exchange and reporting.",
      },
      level2: {
        subtitle: "Multi-Tool Integration & Advanced Use Cases",
        text: "Integrate multiple Adobe tools, develop advanced use cases, create cross-tool reports, and implement data governance policies and extensive training for better collaboration and decision-making.",
      },
      level3: {
        subtitle: "Holistic Adobe Ecosystem & Optimisation",
        text: "Achieve seamless integration of Adobe Analytics with multiple Adobe tools, implement cross-tool advanced use cases, establish robust data governance, and foster a culture of collaboration and continuous improvement.",
      },
      level4: {
        subtitle: "Fully Integrated Adobe Ecosystem & Data-Driven Excellence",
        text: "Establish a fully integrated Adobe ecosystem, leverage data-driven decision-making, harness advanced personalisation and targeting capabilities, and continuously optimise tool functionality for sustainable business growth.",
      },
      level5: {
        subtitle:
          "Lead in implementing and leveraging cutting-edge Adobe features",
        text: "Regularly participate in Adobe's beta programs for early access to new features. Foster a culture of continuous learning and innovation.",
      },
    },
    section3: {
      title: "Non-Adobe tools integration",
      level1: {
        subtitle: "Non-Adobe Tool Integration Foundations",
        text: "Establish basic data exchange with a single non-Adobe tool, identify basic use cases, provide internal training, and assess the benefits and potential improvements of the integration.",
      },
      level2: {
        subtitle: "Multiple Non-Adobe Tool Integration & Custom Use Cases",
        text: "Integrate multiple non-Adobe tools, set up bi-directional data flow, identify custom use cases, provide advanced training, and foster cross-functional collaboration and continuous improvement.",
      },
      level3: {
        subtitle: "Advanced Integration Strategy & Governance",
        text: "Develop a comprehensive integration strategy, establish seamless bi-directional data flow, implement advanced data mapping, define integration governance policies, and provide in-depth training and support.",
      },
      level4: {
        subtitle: "Fully Integrated Ecosystem & Cross-Platform Insights",
        text: "Create a comprehensive integration strategy for a fully integrated ecosystem with non-Adobe tools, develop custom integrations and APIs, leverage cross-platform insights, and foster a culture of knowledge sharing and collaboration.",
      },
      level5: {
        subtitle:
          "Champion in-depth integrations and symbiotic relationships with non-Adobe tools",
        text: "Explore and leverage advanced data exchange capabilities. Lead in setting industry benchmarks for integrations.",
      },
    },
    section4: {
      title: "Mobile app tracking",
      level1: {
        subtitle: "Basic Mobile App Tracking and Analysis",
        text: "Implement basic mobile app tracking, collect essential data points, create basic reports, establish minimal mobile-web integration, and perform basic user behaviour analysis.",
      },
      level2: {
        subtitle: "Enhanced Mobile App Tracking and Advanced Analysis",
        text: "Implement comprehensive tracking across mobile apps, collect enhanced data points, establish stronger integration between mobile app and web data, and perform advanced user behavior analysis and A/B testing.",
      },
      level3: {
        subtitle: "Cross-Platform Tracking and Advanced Integration",
        text: "Establish holistic cross-platform tracking, collect comprehensive data points, ensure seamless integration of web and mobile app data, perform real-time reporting and analysis, and create cross-platform user segments.",
      },
      level4: {
        subtitle:
          "Optimised Cross-Platform Tracking and Continuous Improvement",
        text: "Implement optimised holistic cross-platform tracking, focus on capturing critical KPIs, achieve seamless integration, perform real-time reporting and analysis, and continuously optimise the tracking setup based on evolving business goals and user experience requirements.",
      },
      level5: {
        subtitle:
          "Be at the forefront of cross-platform analytics and personalisation",
        text: "Lead in unifying user data and personalisation across platforms. Push the boundaries in cross-platform analytics",
      },
    },
    section5: {
      title: "Data Layer setup",
      level1: {
        subtitle: "Basic Data Layer Implementation and Management",
        text: "Set up a basic Data Layer, identify key data points, ensure structured data capture, apply consistent naming conventions, perform basic data validation, and maintain clear documentation.",
      },
      level2: {
        subtitle: "Intermediate Data Layer Enhancement and Validation",
        text: "Enhance the Data Layer by capturing a wider range of data points, prioritize additional data points, improve data structuring, perform regular data validation, and provide ongoing training and support.",
      },
      level3: {
        subtitle: "Advanced Data Layer Setup and Optimisation",
        text: "Implement a comprehensive Data Layer, continuously evaluate data points, establish a robust data structure, utilise advanced tagging and tracking techniques, and maintain extensive documentation.",
      },
      level4: {
        subtitle:
          "Streamlined Data Layer Management and Continuous Improvement",
        text: "Focus on capturing critical data points, implement an optimised data structure, utilise advanced tracking techniques, ensure data quality assurance, continuously monitor performance, and foster cross-functional collaboration.",
      },
      level5: {
        subtitle: "Drive innovation in Data Layer management and utilisation",
        text: "Pioneering new ways to leverage the Data Layer for deeper insights. Stay ahead of industry trends in Data Layer management.",
      },
    },
  },
};

export const sectionTitles = {
  category1: "Section 1: Data Collection and Integration",
  category2: "Section 2: Reporting and Analysis",
  category3: "Section 3: Attribution and Marketing",
  category4: "Section 4: Personalisation and Audience Segmentation",
  category5: "Section 5: Data Governance and Privacy",
};

export const sectionArray = [
  "section1",
  "section2",
  "section3",
  "section4",
  "section5",
];

export const overallScoreDescription = {
  Beginner:
    "You may just be starting out, but there’s loads you can do to get value from your data.  Here are some personalised recommendations to get you started:",
  Intermediate:
    "You’re on the road to quality, actionable data, but there’s more to do to see real value from it.  Here are some personalised recommendations to take you to the next stage:",
  Advanced:
    "You’re doing great things with data, but there’s more to do to ensure you’re maximising its value consistently across all areas.  Here are some personalised recommendations to get you to the next level:",
  Expert:
    "Your data capability is industry leading, great work!  Stay at the forefront and keep pioneering new ways to leverage your data.  Here are some personalised recommendations to help you stay ahead of the curve.",
};
