import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./Header";
import Intro from "./Intro";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./LandingPage";
import ErrorPage from "./ErrorPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Intro />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/results/:assessmentID",
    element: (
      <>
        <LandingPage />
      </>
    ),
  },
]);

root.render(
  // <React.StrictMode>
  <>
    <Header />
    <RouterProvider router={router} />
  </>
  // </React.StrictMode>
);

reportWebVitals();
