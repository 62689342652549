import { useRouteError } from "react-router-dom";
import "./survey.scss";

const ErrorPage = () => {
  return (
    <div id="error-page">
      <h1>404 - Oops!</h1>
      <p>The page you're looking for no longer exists.</p>
      <p>
        Return to the <a href="/">homepage</a>
      </p>
    </div>
  );
};

export default ErrorPage;
