export const AWSconfig = {
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
  region: "eu-west-1",
  dynamoDBApiVersion: "2012-08-10",
};

export const freeTextQuestion = "freeTextQuestion";

const surveyQuestions = {
  q1: {
    name: "dataCollectionAndIntegrationQ1",
    title:
      "How well have you implemented Adobe Analytics tracking on your website?",
    weight: 0.25,
    choice1: "No tracking implemented",
    choice2: "Tracking on a few critical pages, limited data points",
    choice3: "Tracking on most pages with a good range of data points",
    choice4: "Comprehensive tracking across all pages and interactions",
    choice5:
      "Optimised tracking with a focus on critical KPIs, user flows, and events",
    category: "category1",
  },
  q2: {
    name: "dataCollectionAndIntegrationQ2",
    title:
      "How effectively have you integrated Adobe Analytics with other Adobe tools (e.g., Target, Experience Manager)?",
    weight: 0.25,
    choice1: "No integration with other Adobe tools",
    choice2: "Basic integration with one Adobe tool",
    choice3: "Integration with a few Adobe tools, limited functionality",
    choice4:
      "Integration with multiple Adobe tools, leveraging synergies and functionalities",
    choice5:
      "Fully integrated ecosystem, seamless data flow, and collaboration across Adobe tools",
    category: "category1",
  },
  q3: {
    name: "dataCollectionAndIntegrationQ3",
    title:
      "How well have you integrated Adobe Analytics with non-Adobe tools (e.g., CRM, ERP)?",
    weight: 0.2,
    choice1: "No integration with non-Adobe tools",
    choice2: "Basic integration with one non-Adobe tool, limited data exchange",
    choice3:
      "Integration with a few non-Adobe tools, some data synchronisation",
    choice4:
      "Integration with multiple non-Adobe tools, bi-directional data flow",
    choice5:
      "Fully integrated ecosystem with non-Adobe tools, seamless data flow, and cross-platform insights",
    category: "category1",
  },
  q4: {
    name: "dataCollectionAndIntegrationQ4",
    title: "Are you using Adobe Analytics to collect data from mobile apps?",
    weight: 0.15,
    choice1: "Not collecting any mobile app data",
    choice2: "Basic mobile tracking with limited data points",
    choice3:
      "Tracking data from both web and mobile apps with some integration",
    choice4:
      "Advanced mobile app tracking with comprehensive data points and integration",
    choice5:
      "Holistic cross-platform tracking, unified view of user behavior across web and app",
    category: "category1",
  },
  q5: {
    name: "dataCollectionAndIntegrationQ5",
    title:
      "How well have you set up your Data Layer to capture and structure data for Adobe Analytics?",
    weight: 0.15,
    choice1: "No Data Layer implemented",
    choice2: "Basic Data Layer capturing a few data points",
    choice3:
      "Intermediate Data Layer with a range of data points and some structuring",
    choice4:
      "Advanced Data Layer capturing a wide range of data points and structured hierarchy",
    choice5:
      "Optimised Data Layer capturing critical data points, well-structured, and easy to maintain",
    category: "category1",
  },
};

const createSurveyQuestions = () => {
  const pages = [];

  // const surveyArray = [];
  for (let i = 1; i <= 5; i++) {
    // Create a new object for each iteration
    const surveyQuestion = {
      elements: [
        {
          type: "radiogroup",
          name: surveyQuestions["q" + i].name,
          isRequired: true,
          category: surveyQuestions["q" + i].category,
          title: surveyQuestions["q" + i].title,
          choices: [
            {
              value: surveyQuestions["q" + i].choice1,
              score: 0,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice2,
              score: 1,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice3,
              score: 2,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice4,
              score: 3,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice5,
              score: 4,
              weight: surveyQuestions["q" + i].weight,
            },
          ],
        },
      ],
    };
    pages.push(surveyQuestion);
  }
  pages.push({
    name: "PersonalDetails",

    elements: [
      {
        type: "text",
        name: "FirstName",
        isRequired: true,
        title: "Enter your first name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "LastName",
        isRequired: true,
        title: "Enter your last name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "CompanyName",
        isRequired: true,
        title: "What company do you work for?",
        hideNumber: true,
      },
      {
        type: "text",
        name: "JobTitle",
        title: "What is your job title?",
        hideNumber: true,
      },
      {
        type: "text",
        name: "Email",
        isRequired: true,
        validators: [{ type: "email" }],
        title: "Enter your email address: (we will email your results to you)",
        hideNumber: true,
      },
      {
        type: "checkbox",
        name: "adobeProducts",
        title: "Which of the following Adobe products do you currently use?",
        description:
          "If you use multiple Adobe products, please select all of them.",
        choices: [
          "Analytics",
          "Customer Journey Analytics",
          "Real-Time CDP",
          "Audience Manager",
          "Advertising",
          "Target",
          "Journey Optimizer",
          "Marketo Engage",
          "Campaign",
          "Experience Manager (Cloud / Sites / Assets / Forms / Guides / Learning)",
          "Ecommerce",
        ],
        colCount: 2,
        showNoneItem: true,
        showOtherItem: true,
        showSelectAllItem: true,
        separateSpecialChoices: true,
        hideNumber: true,
      },
      {
        type: "checkbox",
        name: "contactby",
        title:
          "By ticking this box you agree to collection, use, storage and protection of your personal information as outlined in our privacy policy.",
        isRequired: true,
        choices: [{ text: "I agree" }],
        hideNumber: true,
      },
      {
        type: "html",
        name: "Mylink",
        title: "Our privacy policy",
        html: "<a href='https://station10.co.uk/privacy-policy/'  target='_blank'>Our privacy policy</a>",
        hideNumber: true,
      },
    ],
  });
  return { pages };
};

export const surveyJson = createSurveyQuestions();
